<template>
  <v-card>
    <v-card-title class="justify-center">
      <v-row>
        <v-col cols="12" sm="4">
          <DatePicker v-model="fechaInicio" :label="'Fecha Inicio'" />
        </v-col>
        <v-col cols="12" sm="4">
          <DatePicker v-model="fechaFin" :label="'Fecha Fin'" />
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field v-model="numeroPoliza" label="# Poliza"></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" class="my-auto text-center">
          <v-btn color="primary" dark @click="fetchData()">
            Buscar
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4" class="text-center">
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="downloadExport()"
            disabled
          >
            <v-icon left>
              mdi-download
            </v-icon>
            Descargar
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4" class="text-center" v-if="false">
          <v-btn color="primary" @click="newItem()">Nuevo Registro</v-btn>
        </v-col>
      </v-row>
      <RemisoriaUnionCobroAgregar
        v-if="dialog"
        @saved="fetchData()"
        @dialogChange="dialogChange"
        :dialog="dialog"
        :dato="dato"
      />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="datos"
      hide-default-footer
      item-key="tableId"
    >
      <template v-slot:[`item.Icon`]="{ item }">
        <v-icon
          class="mr-2"
          color="purple"
          v-if="item.tableName == 'remisoria_base'"
          >mdi-new-box</v-icon
        >
      </template>
      <template v-slot:[`item.FechaEmisionPoliza`]="{ item }">
        {{ new Date(item.fechaEmisionPoliza).toLocaleString() }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          color="primary"
          @click="editItem(item)"
          v-if="item.cobro"
          >mdi-information</v-icon
        >
        <v-icon small class="mr-2" color="red" @click="editItem(item)" v-else
          >mdi-credit-card-outline</v-icon
        >
      </template>
    </v-data-table>

    <v-pagination
      v-model="page"
      :length="pages"
      v-on:input="fetchData()"
    ></v-pagination>
  </v-card>
</template>

<script>
import axios from "axios";
import RemisoriaUnionCobroAgregar from "@/components/RemisoriaUnionCobroAgregar.vue";
import RemisoriaUnionService from "@/api/remisoria/RemisoriaUnionService";
import moment from "moment";
import DatePicker from "@/components/shared/DatePïcker.vue";
//new Date().toLocaleString()

export default {
  components: {
    DatePicker,
    RemisoriaUnionCobroAgregar
  },
  data() {
    return {
      fechaInicio: new Date(new Date().getFullYear(), 0, 1)
        .toISOString()
        .substr(0, 10),
      fechaFin: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      )
        .toISOString()
        .substr(0, 10),
      numeroPoliza: "",
      dato: {},
      dialog: false,
      url: process.env.VUE_APP_API,
      page: 1,
      pages: 1,
      datos: [],
      search: "",
      headers: [
        //{ text: "id", align: "start", value: "id" },
        { text: "???", value: "Icon", align: "start", sortable: false },
        {
          text: "Emisión Poliza",
          value: "FechaEmisionPoliza",
          sortable: false
        },
        //{ text: "# Remisoria", value: "numero" },
        //{ text: "# Subpartida", value: "subpartida" },
        { text: "# Poliza", value: "poliza.numero" },
        { text: "Asegurado", value: "nombreAseguradoPrincipal" },
        { text: "Plan", value: "plan.detalle" },
        { text: "primaAnual", value: "primaAnual" },
        { text: "derechoAgencia", value: "derechoAgencia" },
        { text: "derechoEmision", value: "derechoEmision" },
        { text: "valorCobrado", value: "valorCobro" },
        { text: "saldo", value: "getSaldoDisplay" }
        // valor cobrado

        //{ text: "updatedBy", value: "updatedBy.getFullNameDisplay" },
        //{ text: "Acción", value: "actions", sortable: false }
      ]
    };
  },
  methods: {
    setDefaultItem() {
      const newDto = {
        observacion: "",
        primaPagada: 0.0,
        primaAnual: 0.0,
        derechoAgencia: 0.0,
        derechoEmision: 0.0,
        porcentajeAgenciaNuevo: 0.0,
        porcentajeAgenciaRenovacion: 0.0,
        porcentajeAgenteNuevo: 0.0,
        porcentajeAgenteRenovacion: 0.0
      };
      this.dato = newDto;
    },
    dialogChange(value) {
      this.dialog = value;
    },
    editItem(item) {
      this.dato = { ...item };
      this.dato.fecha = new Date(this.dato.fecha);
      this.dato.fechaEmision = new Date(this.dato.fechaEmision);
      this.dato.fechaEmisionPoliza = new Date(this.dato.fechaEmisionPoliza);
      if (this.dato.fechaNoVigencia) {
        this.dato.fechaNoVigencia = new Date(this.dato.fechaNoVigencia);
      } else {
        this.dato.fechaNoVigencia = null;
      }
      this.dato.agente = this.dato.agente.id;
      this.dato.plan = this.dato.plan.id;
      this.dato.compañia = this.dato.compañia.id;

      // cobro items
      if (this.dato.cobro) {
        if (this.dato.cobro.fechaIngreso) {
          this.dato.cobro.fechaIngreso = new Date(this.dato.cobro.fechaIngreso);
        }
        if (this.dato.cobro.fechaAplicacion) {
          this.dato.cobro.fechaAplicacion = new Date(
            this.dato.cobro.fechaAplicacion
          );
        }
      } else {
        this.dato.cobro = {
          observacion: ""
        };
      }
      this.dato.cobro.numeroPoliza = this.dato.numeroPoliza;
      this.dialogChange(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    buscar() {
      this.search = this.filtro;
      //this.fetchData();
    },
    fetchData() {
      console.log("llamando api");
      const params = new URLSearchParams({
        fechaInicio: this.fechaInicio,
        fechaFin: this.fechaFin,
        numeroPoliza: this.numeroPoliza
      }).toString();
      //axios.get("data/datos.json").then(response => {
      axios
        .get(
          this.url +
            "/remisoria-union/paginado?page=" +
            this.page +
            "&limit=10" +
            "&search=" +
            this.search +
            "&" +
            params
        )
        .then(response => {
          this.datos = response.data?.items || [];
          this.pages = response.data?.meta?.totalPages || 0;
        });
    },
    downloadExport() {
      RemisoriaUnionService.getRemisoriasExcel()
        .then(response => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(new Blob([response.data]));
          const today = new Date();
          moment.locale("es");
          link.setAttribute(
            "download",
            `remisoria-union-_${moment(today).format("YYYY-MMMM-DD")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          console.log("no se pudo generar el documento");
        });
    }

    //nuevo
  },
  created() {
    this.fetchData();
  }
};
</script>
