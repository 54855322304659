<template>
  <v-dialog v-bind:value="dialog" persistent max-width="600px">
    <v-form>
      <v-card>
        <v-card-title>
          <span class="headline">{{
            dtoSave.cobro ? "Editar Cobro" : "Nuevo Cobro"
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="# Secuencia"
                  v-model.number="dtoSave.cobro.secuencia"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="# Poliza"
                  v-model="dtoSave.cobro.numeroPoliza"
                  required
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <DateTimePicker
                  label="Fecha Ingreso"
                  v-model="dtoSave.cobro.fechaIngreso"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <DateTimePicker
                  label="Fecha Aplicación"
                  v-model="dtoSave.cobro.fechaAplicacion"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  label="Forma Cobro"
                  :items="formaPagoOptions"
                  :item-text="'text'"
                  :item-value="'value'"
                  v-model="dtoSave.cobro.formaCobro"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Valor Cobro"
                  v-model.number="dtoSave.cobro.valorCobro"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Observaciones"
                  v-model="dtoSave.cobro.observacion"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <small>* campos requeridos</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="save()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "axios";
import DateTimePicker from "@/components/shared/DateTimePicker.vue";
import RemisoriaUnionService from "@/api/remisoria/RemisoriaUnionService";
import PlanService from "@/api/plan/PlanService";
//import "selectize/dist/css/selectize.css";
//import VSelectize from "@isneezy/vue-selectize";
export default {
  components: {
    DateTimePicker
    //VSelectize
  },
  props: ["dialog", "dato"],
  data() {
    return {
      datetime: new Date(),
      url: process.env.VUE_APP_API,
      page: 1,
      pages: 1,
      datos: [],
      search: "",
      dtoSave: {
        cobro: {
          fechaIngreso: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
          ),
          fechaAplicacion: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
          )
        }
      },
      //selects
      tipo1Options: [
        { text: "Nuevo Negocio", value: "1" },
        { text: "Renovación", value: "2" },
        { text: "Adición", value: "3" },
        { text: "Interés de Prestamo", value: "4" },
        { text: "Prestamo", value: "5" }
      ],
      tipo2Options: [
        { text: "Poliza Nueva", value: "1" },
        { text: "Póliza Existente", value: "2" }
      ],
      formaPagoOptions: [
        { text: "TC Internacional", value: "1" },
        { text: "Cheque Electrónico", value: "2" },
        { text: "Cheque Banco Intern", value: "3" },
        { text: "Cheque Local Intern", value: "4" },
        { text: "Transferencia", value: "5" },
        { text: "Valor de Rescate", value: "6" }
      ],
      tipoPolizaOptions: [
        { text: "Salud", value: "H" },
        { text: "Vida", value: "L" },
        { text: "Viaje", value: "T" }
      ],
      modoPagoOptions: [
        { text: "Anual", value: "1" },
        { text: "Semestral", value: "2" },
        { text: "Trimestral", value: "4" },
        { text: "Mensual", value: "12" },
        { text: "Diario", value: "360" }
      ],
      statusVigenciaOptions: [
        { text: "Vigente", value: "V" },
        { text: "No Vigente", value: "NV" }
      ],
      agentes: [],
      planes: [],
      compañias: []
    };
  },
  methods: {
    dialogChange(value) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    buscar() {
      this.search = this.filtro;
      this.fetchData();
    },
    fetchData() {
      console.log("llamando api");
      //axios.get("data/datos.json").then(response => {
      axios
        .get(
          this.url +
            "/datos-base/paginado?page=" +
            this.page +
            "&limit=10" +
            "&search=" +
            this.search
        )
        .then(response => {
          this.datos = response.data?.items || [];
          this.pages = response.data?.meta?.totalPages || 0;
        });
    },
    loadAgentes() {
      axios.get(this.url + "/agentes-base").then(response => {
        this.agentes = response.data || [];
        this.agentes.sort(function(a, b) {
          if (a.nombre > b.nombre) {
            return 1;
          }
          if (b.nombre > a.nombre) {
            return -1;
          }
          return 0;
        });
        //this.pages = response.data?.meta?.totalPages || 0;
      });
    },
    loadCompañias() {
      axios.get(this.url + "/compania-base").then(response => {
        this.compañias = response.data || [];
        this.compañias.sort(function(a, b) {
          if (a.nombre > b.nombre) {
            return 1;
          }
          if (b.nombre > a.nombre) {
            return -1;
          }
          return 0;
        });
        //this.pages = response.data?.meta?.totalPages || 0;
      });
    },
    loadPlanes() {
      const params = new URLSearchParams({
        status: "1"
      }).toString();
      PlanService.getAll(params)
        .then(response => {
          this.planes = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Planes",
            text: "No se pudo obtener"
          });
        });
    },
    save() {
      const dto = { ...this.dtoSave };
      dto.fecha = dto.fecha.toISOString();
      dto.fechaEmision = dto.fechaEmision.toISOString();
      dto.fechaEmisionPoliza = dto.fechaEmisionPoliza.toISOString();
      RemisoriaUnionService.createCobroRemisoria(dto)
        .then(() => {
          //
          this.$emit("saved", "someValue");
          this.close();
        })
        .catch(error => {
          //
          let mensaje = "Intente más tarde";
          if (error.response && error.response.data) {
            mensaje = error.response.data.message;
          }
          this.$swal({
            icon: "error",
            title: "Cobro no se pudo guardar",
            text: mensaje
          });
          //
        });
    }
  },
  created() {
    //this.fetchData();
    this.dtoSave = {};
    this.dtoSave = { ...this.dato };
    this.loadAgentes();
    this.loadPlanes();
    this.loadCompañias();
  }
};
</script>
