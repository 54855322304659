<template>
  <v-card>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ textSucess }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ textError }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-row>
      <v-col cols="12" sm="6">
        <DatePicker v-model="fechaInicio" :label="'Fecha Inicio'" />
      </v-col>
      <v-col cols="12" sm="6">
        <DatePicker v-model="fechaFin" :label="'Fecha Fin'" />
      </v-col>
      <v-col cols="12" class="my-auto text-center">
        <v-btn color="primary" dark @click="fetchData()">
          Buscar
        </v-btn>
      </v-col>
      <v-col cols="12" class="text-center mt-5">
        <v-btn color="primary" dark class="mb-2" @click="downloadExport()">
          <v-icon left>
            mdi-download
          </v-icon>
          Descargar
        </v-btn>
      </v-col>
    </v-row>

    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      loading-text="Cargando datos"
      :loading="loading"
      :headers="headers"
      :search="search"
      :items="datos"
      :footer-props="{
        itemsPerPageOptions: [25, 50, 150, 200]
      }"
      >,
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import PagoService from "@/api/pago/PagoService";
import moment from "moment";
import DatePicker from "@/components/shared/DatePïcker.vue";
export default {
  components: {
    DatePicker
  },
  data() {
    return {
      fechaInicio: new Date().toISOString().substr(0, 10),
      fechaFin: new Date().toISOString().substr(0, 10),
      snackbar: false,
      textSucess: "Archivo subido.",
      timeout: 2000,
      textError: "Error al subir archivo.",
      optionsPage: [25, 50, 150],
      dato: {},
      dialog: false,
      url: process.env.VUE_APP_API,
      datos: [],
      file: null,
      search: "",
      loading: false,
      headers: [
        { text: "Poliza", value: "pago_policy" },
        { text: "Productor", value: "pago_producer" },
        { text: "Comisión", value: "sumaA" },
        { text: "Prima", value: "sumaB" }
      ]
    };
  },
  methods: {
    uploadFile() {
      const formData = new FormData();
      formData.append("file", this.file);
      axios
        .post(this.url + "/pagos/uploadfile", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          console.log("archivo subido", response);
        })
        .catch(error => {
          console.log(error);
        });
    },

    fetchData() {
      console.log("llamando api");
      this.loading = true;
      const params = new URLSearchParams({
        fechaInicio: this.fechaInicio,
        fechaFin: this.fechaFin
      }).toString();
      axios
        .get(this.url + "/pagos/reporte" + "?" + params)
        .then(response => {
          console.log(response);
          this.datos = response.data;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    },

    downloadExport() {
      PagoService.getReportePagosExcel(this.fechaInicio, this.fechaFin)
        .then(response => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(new Blob([response.data]));
          const today = new Date();
          moment.locale("es");
          link.setAttribute(
            "download",
            `pago-reporte_${moment(today).format("YYYY-MMMM-DD")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          console.log("no se pudo generar el documento");
        });
    }

    //nuevo
  },
  created() {
    this.fetchData();
    console.log(this.datosLength);
  },

  computed: {
    isDisabled: function() {
      if (this.file != null) return false;
      return true;
    }
  }
};
</script>
<style scoped>
.v-text-field {
  padding-inline: 10px;
}
</style>
