import httpClient from "../http-client";

const ENDPOINT = "/remisoria-union";

class RemisoriaUnionService {
  getRemisoriasExcel() {
    return httpClient.get(`${ENDPOINT}/excel`, {
      responseType: "blob"
    });
  }

  createCobroRemisoria(dto: any) {
    return httpClient.post(`${ENDPOINT}/cobro`, dto);
  }

  /*

  cerrarRemisorias(dto: any) {
    return httpClient.post(`${ENDPOINT}/cierre-anual`, dto);
  }
  */
}
export default new RemisoriaUnionService();
