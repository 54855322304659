








































































import Vue from "vue";
import { EMAIL_REGEX } from "../utils/valid";
import AuthStore from "@/store/modules/AuthStore";
//import ResetPassword from "@/components/web/auth/ResetPassword.vue";

export default Vue.extend({
  components: {
    //ResetPassword
  },
  data() {
    return {
      dialogResetPassword: false,
      loading: false,
      email: "",
      password: "",
      rules: [(v: string) => !!v || "Campo requerido"],
      emailRules: [
        (v: string) => !!v || "Campo requerido",
        (v: string) => EMAIL_REGEX.test(v) || "El correo no es válido"
      ]
    };
  },
  methods: {
    dialogChangeResetPassword(value: boolean) {
      this.dialogResetPassword = value;
    },
    login() {
      if ((this.$refs.login as Vue & { validate: () => boolean }).validate()) {
        this.loading = true;
        //const authDto = { username: this.email, password: this.password };
        const authDto = { username: this.email, password: this.password };
        AuthStore.login(authDto)
          .then(() => {
            this.$swal({
              toast: true,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 1500,
              icon: "success",
              title: "Login",
              text: "Exitoso"
            }).then(() => {
              //this.$router.push("/");
              this.$router.push("admin");
            });
          })
          .catch(() => {
            //this.loading = false;
            this.$swal({
              toast: true,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 1500,
              icon: "error",
              title: "Login",
              text: "Fallido"
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }

      //AuthStore.updateLoggedIn(true);
    }
  }
});
