import httpClient from "../http-client";

const ENDPOINT = "/pagos";

class PagoService {
  getReportePagosExcel(fechaInicio: string, fechaFin: string) {
    const params = new URLSearchParams({
      fechaInicio,
      fechaFin
    }).toString();
    return httpClient.get(`${ENDPOINT}/reporte-excel` + "?" + params, {
      responseType: "blob"
    });
  }
}
export default new PagoService();
