<template>
  <v-card>
    <!--
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ textSucess }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ textError }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-card-title class="justify-center">
      <v-file-input
        v-model="file"
        placeholder="Upload your documents"
        prepend-icon="mdi-paperclip"
      >
        <template v-slot:selection="{ text }">
          <v-chip small label color="primary">
            {{ text }}
          </v-chip>
        </template>
      </v-file-input>
      <v-btn color="primary" @click="uploadFile()" :disabled="isDisabled"
        >Subir</v-btn
      >
    </v-card-title>
    -->
    <v-row>
      <v-col cols="12" sm="6">
        <DatePicker v-model="fechaInicio" :label="'Fecha Inicio'" />
      </v-col>
      <v-col cols="12" sm="6">
        <DatePicker v-model="fechaFin" :label="'Fecha Fin'" />
      </v-col>
      <v-col cols="12" class="my-auto text-center">
        <v-btn color="primary" dark @click="fetchData()">
          Buscar
        </v-btn>
      </v-col>

      <v-col cols="12" class="text-center mt-5">
        <v-btn color="primary" dark class="mb-2" @click="exportExcel()">
          <v-icon left>
            mdi-download
          </v-icon>
          Descargar
        </v-btn>
      </v-col>
    </v-row>

    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      height="58vh"
      fixed-header
      id="tablita"
      loading-text="Cargando datos"
      :loading="loading"
      :headers="headers"
      :search="search"
      :items="datos"
      items-per-page="-1"
      hide-default-footer
      >,
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"
          >mdi-information</v-icon
        >
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import DatePicker from "@/components/shared/DatePïcker.vue";
import PagoService from "@/api/pago/PagoService";
import axios from "axios";
import moment from "moment";
import XLSX from "xlsx";
export default {
  components: {
    DatePicker
  },
  data() {
    return {
      // inicio fechas
      fechaInicio: new Date().toISOString().substr(0, 10),
      fechaFin: new Date().toISOString().substr(0, 10),
      // fin fechas
      snackbar: false,
      textSucess: "Archivo subido.",
      timeout: 2000,
      textError: "Error al subir archivo.",
      optionsPage: [25, 50, 150],
      dato: {},
      dialog: false,
      url: process.env.VUE_APP_API,
      datos: [],
      file: null,
      search: "",
      loading: false,
      headers: [
        { text: "ID", align: "start", value: "id", filterable: false },
        { text: "SUPERVISOR", value: "supervisor" },
        { text: "PRODUCTOR", value: "producer" },
        { text: "LÍNEA DE NEGOCIO", value: "line_of_business" },
        { text: "CODIGO PRODUCTO", value: "product_code" },
        { text: "CODIGO NOMBRE", value: "product_name" },
        { text: "TIPO DE NEGOCIO", value: "type_of_business", sortable: false },
        { text: "PÓLIZA", value: "policy" },
        { text: "ASEGURADO PRINCIPAL", value: "main_insured" },
        { text: "MODO", value: "mode", sortable: false },
        { text: "PRIMA ANUAL", value: "annual_premium", sortable: false },
        { text: "PRIMA OBJETIVO ANUAL	", value: "annual_target_premium" },
        { text: "PRIMA MODAL", value: "modal_premium", sortable: false },
        { text: "FECHA DE EMISIÓN", value: "issue_date" },
        { text: "TIPO DE FACTURACIÓN", value: "billing_type", sortable: false },
        {
          text: "FECHA DE APLICACIÓN DEL PAGO",
          value: "payment_allocation_date",
          sortable: false
        },
        {
          text: "FECHA DE VENCIMIENTO",
          value: "payment_due_date",
          sortable: false
        },
        { text: "PAGADA HASTA", value: "paid_through_date" },
        { text: "IMPORTE DEL PAGO", value: "payment_amount" },
        { text: "CANAL DE PAGO", value: "payment_channel" },
        { text: "ES RECURRENTE?", value: "is_it_recurrent" },
        /*{ text: "COMISIONES", value: "commission", sortable: false },
        {
          text: "FECHA DE PAGO DE COMISIONES",
          value: "commission_paid_date"
        },*/
        { text: "ESTA EN SUSPENSO?", value: "is_it_in_suspense" },
        {
          text: "TARJETA DE CRÉDITO (ÚLTIMOS 4 DÍGITOS)",
          value: "credit_card_4_last_digits",
          sortable: false
        },
        {
          text: "TARJETA DE CRÉDITO (FECHA DE EXPIRACIÓN)",
          value: "credit_card_expiration_date",
          sortable: false
        },
        {
          text: "TARJETA DE CRÉDITO (TITULAR)",
          value: "credit_card_holder",
          sortable: false
        },
        {
          text: "NÚMERO DE ORDEN DE PAGO",
          value: "payment_order_number",
          sortable: false
        },
        /*
        {
          text: "PRIMA COMISIONABLE",
          value: "commissionable_premium",
          sortable: false
        },
        {
          text: "PRIMA COMISIONABLE (EXCESO)",
          value: "commissionable_premium_excess",
          sortable: false
        },
        */
        { text: "DERECHO DE EMISIÓN", value: "policy_fee", sortable: false }
      ]
    };
  },
  methods: {
    uploadFile() {
      const formData = new FormData();
      formData.append("file", this.file);
      axios
        .post(this.url + "/pagos/uploadfile", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Pago",
            text: "Procesado de forma exitosa"
          });
        })
        .catch(error => {
          //let mensaje = error.response.data.message 'por algun motivo'
          let mensaje = "Intente más tarde";
          if (error.response && error.response.data) {
            mensaje = error.response.data.message;
          }
          this.$swal({
            icon: "error",
            title: "Pago",
            text: "No se pudo procesar el archivo: " + mensaje
          });
        });
    },

    fetchData() {
      console.log("llamando api");
      this.loading = true;
      const params = new URLSearchParams({
        fechaInicio: this.fechaInicio,
        fechaFin: this.fechaFin
      }).toString();
      axios
        .get(this.url + "/pagos" + "?" + params)
        .then(response => {
          console.log(response);
          this.datos = response.data;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    },

    exportExcel() {
      const today = new Date();
      moment.locale("es");
      //const data = XLSX.utils.json_to_sheet(this.datos);
      const data = XLSX.utils.table_to_sheet(
        document.getElementById("tablita")
      );
      const workbook = XLSX.utils.book_new();

      const filename = "reporte-cobranza";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(
        workbook,
        `${filename}_${moment(today).format("YYYY-MMMM-DD")}.xlsx`
      );
    }

    //nuevo
  },
  created() {
    this.fetchData();
    console.log(this.datosLength);
  },

  downloadExport() {
    PagoService.getReportePagosExcel()
      .then(response => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        const today = new Date();
        moment.locale("es");
        link.setAttribute(
          "download",
          `pago-reporte_${moment(today).format("YYYY-MMMM-DD")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        console.log("no se pudo generar el documento");
      });
  },

  computed: {
    isDisabled: function() {
      if (this.file != null) return false;
      return true;
    }
  }
};
</script>
<style scoped>
.v-text-field {
  padding-inline: 10px;
}
</style>
