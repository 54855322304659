




































import Vue from "vue";
import DateTimePicker from "@/components/shared/DateTimePicker.vue";
import RemisoriaService from "@/api/remisoria/RemisoriaService";
export default Vue.extend({
  components: {
    DateTimePicker
  },
  data() {
    return {
      loading: false,
      dtoSave: {
        anioCierre: new Date().getFullYear(),
        fechaInicio: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ),
        fechaFin: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + 1
        )
      }
    };
  },
  methods: {
    save() {
      this.loading = true;
      const dto = {
        ...this.dtoSave,
        fechaInicio: this.dtoSave.fechaInicio.toISOString(),
        fechaFin: this.dtoSave.fechaFin.toISOString()
      };
      RemisoriaService.cerrarRemisorias(dto)
        .then(() => {
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Cierre de Remisorias",
            text: "Guardado exitosamente"
          });
        })
        .catch(error => {
          //
          let mensaje = "Intente más tarde";
          if (error.response && error.response.data) {
            mensaje = error.response.data.message;
          }
          this.$swal({
            icon: "error",
            title: "Cierre de Remisorias",
            text: mensaje
          });
          //
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
